import React, { useState, useContext, useEffect } from "react";
import { Navbar } from "../components/navbar/Navbar.jsx";
import { HeroSmall } from "../components/hero/HeroSmall";
import { Footer } from "../components/footer/Footer.jsx";
import { getAirports, getSchedulesLast, getUserInfo } from "../middleware/core";

import { UserContext } from "../UserContext";
import { isAuthenticated, refresh } from "../middleware/auth"

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
export const RouteChangelog = () => {
  const [schedules, setSchedules] = useState([])
  const [loading, setLoading] = useState(true);
  const [airports, setAirports] = useState({});

  const { userContextData, setUserContextData } = useContext(UserContext);
  useEffect(() => {
    const getInitialData = async () => {
      if (isAuthenticated()) {
        const user = await refresh();
        const userInfo = await getUserInfo(user.public_id);
        user.info = userInfo;
      }

      let newAirportsMap = {};
      const airport_data = await getAirports();
      setAirports(airport_data);

      const data = await getSchedulesLast();
      data.sort((a, b) => a.date < b.date ? 1 : -1);
      setSchedules(data);
      await Promise.all(data[0].schedules.map(async schedule => {
        try {
          // console.log(newAirportsMap[schedule.arr_icao].name)
        } catch (error) {
          // const req = await fetch('http://localhost:8787/airports', {
          //   method: 'POST',
          //   headers: {
          //     'x-access-token': localStorage.getItem('_t'),
          //     'content-type': 'application/json'
          //   },
          //   body: JSON.stringify({
          //     icao: schedule.arr_icao,
          //     carrierId: 'de175f0e-6545-4fc0-ad70-43179cec5318'
          //   })
          // });
          console.error(schedule.arr_icao)
        }
      }))

      setLoading(false);
    }

    getInitialData()
  }, []);

  if (loading) {
    return (<>
      <div className="section-intro">
        <Navbar user={userContextData} />
        <HeroSmall title="Route Changelog" subTitle="FlyvFrontier" />
      </div>
      <div className="uk-section uk-padding-medium">
        <div className="uk-container">
          <div className="uk-card uk-card-default uk-padding-small">
            <h3 className="uk-heading-line uk-text-left uk-padding-small uk-margin-remove-bottom"><span>Route Upload Dates</span></h3>
            <ul uk-accordion="true">
              {Array(10).fill(0).map((_, i) => (
                <Skeleton height={30} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>)
  }
  return (
    <>
      <div className="section-intro">
        <Navbar user={userContextData} />
        <HeroSmall title="Route Changelog" subTitle="FlyvFrontier" />
      </div>
      <div className="uk-section uk-padding-medium">
        <div className="uk-container">
          <div className="uk-card uk-card-default uk-padding-small">
            <h3 className="uk-heading-line uk-text-left uk-padding-small uk-margin-remove-bottom"><span>Route Upload Dates</span></h3>
            <ul uk-accordion="true">
              {schedules.length > 0 ? (
                schedules.map((schedule, i) => (
                  <li className={i === 0 ? 'uk-open uk-text-left uk-padding-small uk-margin-remove-top' : 'uk-text-left uk-padding-small uk-margin-remove-top'}>
                    <a className="uk-accordion-title" href="#">{new Date(schedule.date).toLocaleDateString(undefined, { year: "numeric", month: "long", day: "numeric" })}</a>
                    <div className="uk-accordion-content" hidden={true}>
                      <table className="uk-table uk-table-middle uk-table-divider uk-table-hover uk-table-responsive uk-margin-remove-top">
                        <thead>
                          <tr>
                            <th>Departure Airport</th>
                            <th>Arrival Airport</th>
                          </tr>
                        </thead>
                        <tbody>
                          {schedule.schedules.map(s => (
                            <tr>

                              {airports.find(a => a.icao === s.dep_icao) ? (
                                <td>{s.dep_icao} - {airports.find(a => a.icao === s.dep_icao).name}</td>
                              ) : (
                                <td>{s.dep_icao} - <Skeleton width={100} /></td>)
                              }
                              {airports.find(a => a.icao === s.arr_icao) ? (
                                <td>{s.arr_icao} - {airports.find(a => a.icao === s.arr_icao).name}</td>
                              ) : (
                                <td>{s.arr_icao} - <Skeleton width={100} /></td>)
                              }
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    {schedules.length > i + 1 ? <hr className="uk-divider-small uk-margin-medium-top"></hr> : <></>}
                  </li>
                ))
              ) : (<>
                <div className="uk-width-1-1">
                  <Skeleton height={80} />
                </div>
                <div className="uk-width-1-1">
                  <Skeleton height={80} />
                </div>
                <div className="uk-width-1-1">
                  <Skeleton height={80} />
                </div>
                <div className="uk-width-1-1">
                  <Skeleton height={80} />
                </div>
              </>
              )}
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
