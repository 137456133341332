import React, { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { getLatestFlightsByCarrierId } from "../../middleware/core";

export const LatestFlights = () => {
  const [flights, setFlights] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  useEffect(() => {
    const getInitialData = async () => {
      setLoading(true);
      const flightsRequestData = await getLatestFlightsByCarrierId(7);
      setFlights(flightsRequestData);
      setLoading(false);
    };

    getInitialData();
  }, []);

  return (
    <div className="uk-section uk-section-default uk-section-large uk-padding-remove-top">
      <div className="uk-container">
        <div
          className="uk-child-width-expand@s uk-text-left uk-grid uk-grid-match uk-grid-medium"
          uk-grid="true"
        >
          <div>
            <h4 className="uk-h1 uk-margin-top">Latest Flights</h4>
            {flights ? (
              <div className="uk-card uk-card-default latest-flights uk-padding-small">
                {flights.length > 0 ? (
                  <table className="uk-table uk-table-middle uk-table-hover uk-table-responsive">
                    <thead>
                      <tr>
                        <th>Username</th>
                        <th className="uk-width-small">Flight #</th>
                        <th>Dep ICAO</th>
                        <th>Arr ICAO</th>
                        <th>Date</th>
                        <th>Block Flight Time</th>
                        <th>Equipment</th>
                        <th>LR</th>
                        <th>Status</th>
                        <th>Details</th>
                      </tr>
                    </thead>

                    <tbody>
                      {flights.map((flight) => (
                        <tr key={"flight-" + flight.public_id}>
                          <td className="uk-flex  uk-flex-middl">
                            <div
                              //src={props.user.info ? `https://flyvfrontier.b-cdn.net/${props.user.info.avatar_url}` : ''}
                              className="uk-border-circle profile-image bg-image"
                              style={
                                flight.user
                                  ? {
                                      background: `url('${
                                        flight.user.avatar_url
                                          ? flight.user.avatar_url
                                          : "https://rotate-group.b-cdn.net/user-images/default.jpg"
                                      }') 0% 0% / cover`,
                                    }
                                  : {}
                              }
                            ></div>
                            <p className="uk-padding-small uk-margin-remove-top uk-padding-remove-top uk-padding-remove-bottom">
                              {flight.user.username}
                            </p>
                          </td>
                          <td>
                            {flight.schedule.callsign}
                            {flight.schedule.flight_number}
                          </td>
                          <td>{flight.schedule.dep_icao}</td>
                          <td>{flight.schedule.arr_icao}</td>
                          <td>
                            {new Date(flight.dep_time)
                              .toLocaleString()
                              .replace(",", "")}
                          </td>
                          <td>
                            {flight.flight_time
                              ? flight.flight_time.toFixed(1)
                              : "-"}
                          </td>
                          <td>{flight.aircraft_type}</td>
                          <td>
                            -{flight.lr ? flight.lr : ""}
                            {parseFloat(flight.lr) < 50 ? " 🧈" : ""}
                          </td>
                          <td>
                            {flight.flight_time ? (
                              flight.approved ? (
                                <span className="uk-label uk-label-success">
                                  Approved
                                </span>
                              ) : (
                                <span className="uk-label uk-label-danger">
                                  Rejected
                                </span>
                              )
                            ) : (
                              <span className="uk-label uk-label-info">
                                In Progress
                              </span>
                            )}
                          </td>
                          <td>
                            {flight.completed ? (
                              <Link
                                to={`route/detail/${flight.public_id}`}
                                className="uk-button uk-button-default"
                                type="button"
                              >
                                Details
                              </Link>
                            ) : (
                              <></>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <>
                    {flights.length === 0 && loading ? (
                      <>
                        <div className="uk-width-1-1">
                          <Skeleton count={6} height={80} />
                        </div>
                      </>
                    ) : (
                      <>No flights yet.</>
                    )}
                  </>
                )}
              </div>
            ) : (
              <>
                {!loading ? (
                  <div className="uk-width-1-1">
                    <div className="uk-card uk-padding uk-card-default uk-card-body uk-text-left@m uk-text-center">
                      <div className="uk-grid" uk-grid="true">
                        <div className="uk-width-auto@m uk-width-1-1 uk-margin-auto-top uk-margin-auto-bottom">
                          <h3 className="uk-h1 uk-align-center">
                            No flights yet.
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatestFlights;
