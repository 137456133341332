import React, { useState, useContext, useEffect } from "react";
import { Navbar } from "../components/navbar/Navbar.jsx";
import { HeroFlightsMap } from "../components/hero/HeroFlightsMap";
import { Footer } from "../components/footer/Footer.jsx";
import { Link } from "react-router-dom";
import { getAirports, getSchedules } from "../middleware/core";

import { UserContext } from "../UserContext";
import { isAuthenticated, refresh } from "../middleware/auth";
import { getUserInfo } from "../middleware/core";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
export const FlightReports = () => {
  const [schedulesInitial, setSchedulesInitial] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [flights, setFlights] = useState([]);
  const [loading, setLoading] = useState(true);
  const [airportsMap, setAiportsMap] = useState(null);
  const [airportsArray, setAiportsArray] = useState([]);
  const [schedulesMap, setSchedulesMap] = useState(null);
  const [userStats, setUserStats] = useState(null);

  const { userContextData, setUserContextData } = useContext(UserContext);

  useEffect(() => {
    const getInitialData = async () => {
      if (isAuthenticated()) {
        let newAirportsMap = {};
        let newSchedulesMap = {};

        // Get user data
        const user = await refresh();
        const [userInfo, airports] = await Promise.all([getUserInfo(user.public_id), getAirports()]);
        setAiportsArray(airports);
        user.info = userInfo;
        setUserStats({ total_hours: userInfo.total_hours, total_flights: userInfo.total_flights, avg_landing_rate: userInfo.average_landing_rate });

        setUserContextData(user);

        // Get users flights
        const userFlights = userInfo.flights
        // Create Airports Map
        airports.forEach((airport) => {
          newAirportsMap[airport.icao] = airport;
        });
        // Create Schedules Map
        userFlights.forEach((flight) => {
          const schedule = flight.schedule;
          newSchedulesMap[schedule.public_id] = schedule;
        });

        const newFlights = [];

        if (userFlights.length) {
          // Sort Flights
          const sortedFlights = userFlights.sort((a, b) =>
            b.dep_time > a.dep_time ? 1 : -1
          );

          sortedFlights.forEach((flight) => {
            if (flight.log) {
              const log = JSON.parse(flight.log);
              log.forEach((entry) => {
                if (entry.event.includes("Landed")) {
                  const landingRate = parseInt(
                    entry.event.replace(/[^.\d]/g, "")
                  );
                  if (landingRate) flight.lr = landingRate;
                }
              });
            }
            newFlights.push(flight);
          });
        }

        // Set state
        setAiportsMap(newAirportsMap);
        setSchedulesMap(newSchedulesMap);
        setFlights(newFlights);
      }
      const data = await getSchedules();
      setSchedulesInitial(data);
      setSchedules(data);
      setLoading(false);
    };

    getInitialData();
  }, []);

  return (
    <>
      <div className="section-intro">
        <Navbar user={userContextData} hideRibbon={true} />
        <HeroFlightsMap
          title="Logbook"
          subTitle="Crew Center"
          flights={flights}
          airports={airportsArray}
          airportsMap={airportsMap}
        />
      </div>
      <div className="uk-section uk-padding-medium">
        <div className="uk-container">
          <div
            className="uk-child-width-1-3@m uk-grid-small uk-grid-match flights-stats"
            uk-grid="true"
          >
            <div>
              <div
                className="uk-card uk-card-primary uk-card-body bg-image"
                style={{
                  background: 'url("./images/stats-4.jpg")',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  boxShadow:
                    "inset 0 0 0 2000px rgba(0, 0, 0, 0.5), 0 5px 15px rgb(0 0 0 / 18%)",
                }}
              >
                <p className="uk-text-left uk-margin-remove uk-text-large">
                  Total Hours
                </p>
                <h3 className="uk-card-title uk-text-left uk-margin-remove uk-text-bold">
                  {userStats == null ? (
                    <>-</>
                  ) : (
                    userStats.total_hours.toFixed(1)
                  )}
                </h3>
              </div>
            </div>
            <div>
              <div
                className="uk-card uk-card-primary uk-card-body bg-image"
                style={{
                  background: 'url("./images/stats-3.jpg")',
                  backgroundSize: 'cover',
                  boxShadow:
                    "inset 0 0 0 2000px rgba(0, 0, 0, 0.5), 0 5px 15px rgb(0 0 0 / 18%)",
                }}
              >
                <p className="uk-text-left uk-margin-remove uk-text-large">
                  Total Flights
                </p>
                <h3 className="uk-card-title uk-text-left uk-margin-remove uk-text-bold">
                  {userStats == null ? <>-</> : userStats.total_flights}
                </h3>
              </div>
            </div>
            <div>
              <div
                className="uk-card uk-card-secondary uk-card-body bg-image"
                style={{
                  background: '#04C3D3',
                  backgroundPosition: "center",
                  backgroundSize: 'cover',
                }}
              >
                <p className="uk-text-left uk-margin-remove uk-text-large">
                  Avg Landing Rate
                </p>
                <h3 className="uk-card-title uk-text-left uk-margin-remove uk-text-bold">
                  {userStats == null ? (
                    <>-</>
                  ) : (
                    `-${userStats.avg_landing_rate}`
                  )}
                </h3>
              </div>
            </div>
          </div>
          <br></br>
          {flights ? (
            <div className="uk-card uk-card-default uk-padding-small">
              {flights.length > 0 ? (
                <table className="uk-table uk-table-middle uk-table-divider uk-table-hover uk-table-responsive">
                  <thead>
                    <tr>
                      <th className="uk-width-small">Flight #</th>
                      <th>Dep ICAO</th>
                      <th>Arr ICAO</th>
                      <th>Date</th>
                      <th>Block Flight Time</th>
                      <th>Equipment</th>
                      <th>LR</th>
                      <th>Status</th>
                      <th>Details</th>
                    </tr>
                  </thead>

                  <tbody>

                    {flights.map((flight) => (
                      <tr>
                        <td>
                          {schedulesMap[flight.schedule_id].callsign}
                          {schedulesMap[flight.schedule_id].flight_number}
                        </td>
                        <td>{schedulesMap[flight.schedule_id].dep_icao}</td>
                        <td>{schedulesMap[flight.schedule_id].arr_icao}</td>
                        <td>
                          {new Date(flight.dep_time)
                            .toLocaleString()
                            .replace(",", "")}
                        </td>
                        <td>
                          {flight.flight_time
                            ? flight.flight_time.toFixed(1)
                            : "-"}
                        </td>
                        <td>{flight.aircraft_type}</td>
                        <td>
                          -{flight.lr ? flight.lr : ""}
                          {parseFloat(flight.lr) < 50 ? " 🧈" : ""}
                        </td>
                        <td>
                          {flight.flight_time ? (
                            flight.approved ? (
                              <span className="uk-label uk-label-success">
                                Approved
                              </span>
                            ) : (
                              <span className="uk-label uk-label-danger">
                                Rejected
                              </span>
                            )
                          ) : (
                            <span className="uk-label uk-label-info">
                              In Progress
                            </span>
                          )}
                        </td>
                        <td>
                          {flight.completed ? (
                            <Link
                              to={`route/detail/${flight.public_id}`}
                              className="uk-button uk-button-default"
                              type="button"
                              disable="true"
                            >
                              Details
                            </Link>
                          ) : (
                            <></>
                          )}
                        </td>
                      </tr>
                    ))}

                  </tbody>

                </table>
              ) : (
                <>
                  {flights.length === 0 && loading ? <>
                    <div className="uk-width-1-1">
                      <Skeleton count={6} height={80} />
                    </div>
                  </> : <>No flights yet, Book Now! <Link to="book">Book a Flight</Link></>}
                </>
              )}
            </div>
          ) : (
            <>
              {!loading ? (
                <div className="uk-width-1-1">
                  <div className="uk-card uk-padding uk-card-default uk-card-body uk-text-left@m uk-text-center">
                    <div className="uk-grid" uk-grid="true">
                      <div className="uk-width-auto@m uk-width-1-1">
                        <img
                          style={{ width: "125px" }}
                          className="uk-margin-right@m uk-align-center"
                          src="./images/circles_150x150_land-griz.png"
                        ></img>
                      </div>
                      <div className="uk-width-auto@m uk-width-1-1 uk-margin-auto-top uk-margin-auto-bottom">
                        <h3 className="uk-h1 uk-align-center">
                          Oooops, You have not completed a flight yet.
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};
