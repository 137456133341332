import React, { useEffect, useState } from "react";

const DocView = (props) => {
  const [render, setRender] = useState(false);
  const url =
    "https://docs.google.com/gview?url=" + props.url + "&embedded=true";

  useEffect(() => {
    window.scrollTo(0, 0);
    // If iframe is not loaded, reload it

    const tryRender = () => {
      if (!render) {
        try {
          // reload component
          console.log("try");
          document.getElementById(props.id).contentWindow.location.reload();
        } catch {
          clearInterval(interval);
          setRender(true);
          return;
        }
        document.getElementById(props.id).src = document.getElementById(
          props.id
        ).src;
      }
    };
    const interval = setInterval(tryRender, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      {!render && <>Fetching Brief From Dispatch...</>}
      <iframe
        id={props.id}
        style={props.style}
        src={url}
        frameBorder="0"
      ></iframe>
    </>
  );
};

export default DocView;
