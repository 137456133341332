import React, { useState, useContext, useEffect } from "react";
import { Navbar } from "../components/navbar/Navbar.jsx";
import { HeroSmall } from "../components/hero/HeroSmall";
import { Footer } from "../components/footer/Footer.jsx";
import { Link } from "react-router-dom";
import { getBids, deleteBid } from "../middleware/core";

import { UserContext } from "../UserContext";
import { isAuthenticated, refresh } from "../middleware/auth";
import { getUserInfo, getSimbriefByBidId } from "../middleware/core";
import DocView from "../components/DocView.jsx";

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

import logo from "../images/logo-white.png";

export const ManageSchedules = () => {
  const [bidsInitial, setBidsInitial] = useState([])
  const [bids, setBids] = useState([])
  const [loading, setLoading] = useState(true);

  const { userContextData, setUserContextData } = useContext(UserContext);
  useEffect(() => {
    const getInitialData = async () => {
      if (isAuthenticated()) {
        const user = await refresh();
        const userInfo = await getUserInfo(user.public_id);
        user.info = userInfo;
        setUserContextData(user);
        const data = await getBids(user.access_token);


        // Check simbrief for each bid
        const bidsWithSimbrief = await Promise.all(data.map(async (bid) => {
          const simbrief = await getSimbriefByBidId(bid.public_id, user.info.simbrief_id) || null;
          if (!simbrief.fetch.status.includes('Error') || !simbrief) {
            return { ...bid, simbrief, bidSelected: false };
          } else {
            return { ...bid, bidSelected: false };
          }
        }))
        setBidsInitial(bidsWithSimbrief);
        setBids(bidsWithSimbrief);
        setLoading(false);
      }
    }

    getInitialData()
  }, []);

  const convertTime = (blockTimeString) => {
    let decimalTime = parseFloat(blockTimeString);
    decimalTime = decimalTime * 60 * 60;
    let hours = Math.floor((decimalTime / (60 * 60)));
    decimalTime = decimalTime - (hours * 60 * 60);
    let minutes = Math.floor((decimalTime / 60));
    return (`${hours > 0 ? `${hours}hr` : ''} ${minutes > 0 ? `${minutes}min` : ''}`)
  }

  const deleteFlight = async (e, id) => {
    e.preventDefault();
    e.persist();
    const req = await deleteBid(id, userContextData.access_token);
    if (req.status === 'ok') {
      e.target.innerText = 'Deleted';
    } else {
      e.target.innerText = 'Already Deleted';
    }
  }

  return (
    <>
      <div className="section-intro">
        <Navbar user={userContextData} />
        <HeroSmall title="Manage bids" subTitle="Crew Center" />
      </div>
      <div className="uk-section uk-padding-medium">
        <div className="uk-container">
          <div className="uk-grid-small" uk-grid="true">
            {bids.length > 0 ? (
              bids.map((bid) => (
                <div className="uk-width-1-1">
                  <div className="uk-card uk-padding uk-card-default">
                    <div className="uk-grid uk-text-left" uk-grid="true">
                      <div className="uk-first-column uk-width-auto">
                        <h4 className="uk-h6 uk-margin-remove">
                          Flight Number
                        </h4>
                        <h3 className="uk-h2 uk-margin-remove sky">{bid.schedule.callsign}{bid.schedule.flight_number}</h3>
                      </div>
                      <div>
                        <h4 className="uk-h6 uk-margin-remove">
                          Departure - Arrival
                        </h4>
                        <h3 className="uk-h3 uk-margin-remove">{bid.schedule.dep_icao} - {bid.schedule.arr_icao}</h3>
                      </div>
                      <div>
                        <h4 className="uk-h6 uk-margin-remove">Equipment</h4>
                        <h3 className="uk-h3 uk-margin-remove">{bid.schedule.aircraft_type}</h3>
                      </div>
                      <div>
                        <h4 className="uk-h6 uk-margin-remove">Flight Time</h4>
                        <h3 className="uk-h3 uk-margin-remove">{convertTime(bid.schedule.est_flight_time)}</h3>
                      </div>
                      <div className="uk-width-1-6 uk-visible@m">
                        <h4 className="uk-h6 uk-margin-remove">Notes</h4>
                        <h3 className="uk-h5 uk-margin-remove">
                          {bid.schedule.notes}
                        </h3>
                      </div>
                      <div className="uk-align-right@l uk-margin-auto-left@l uk-margin-remove">
                        {!bid.simbrief ? (
                          <a
                            className="uk-button uk-button-default uk-button uk-margin-small-right"
                            // onClick={(e) => deleteFlight(e, bid.public_id)}
                            href={`http://www.simbrief.com/system/dispatch.php?airline=${bid.schedule.callsign || ''}&fltnum=${bid.schedule.flight_number}&type=${bid.schedule.aircraft_type}&orig=${bid.schedule.dep_icao}&dest=${bid.schedule.arr_icao}${bid.schedule.route ? `&route=${bid.schedule.route}` : ''}&static_id=${bid.public_id}&planformat=${bid.schedule.callsign || 'LIDO'}&manualrmk=${window.location.origin.replace('https://', '').replace('http://', '')}`}
                            target="_blank"
                            rel="noopener"
                          >
                            Dispatch
                          </a>
                        ) : (<>
                          {userContextData.info.simbrief_id && (
                            <a
                              className="uk-button uk-button-default uk-button uk-margin-small-right"
                              onClick={(e) => {
                                e.preventDefault();
                                if (!bid.bidSelected) {
                                  bid.bidSelected = true;
                                } else {
                                  bid.bidSelected = false;
                                }
                                setBids(bids.map((b) => {
                                  if (b.public_id === bid.public_id) {
                                    return bid;
                                  }
                                  return b;
                                }))

                              }}
                              target="_blank"
                              rel="noopener"
                            >
                              {!bid.bidSelected ? 'View Brief' : 'Hide Brief'}
                            </a>)}</>
                        )}
                        <Link
                          className="uk-button uk-button-danger uk-button"
                          onClick={(e) => deleteFlight(e, bid.public_id)}
                          to=""
                        >
                          Remove
                        </Link>
                        <br />
                        {!bid.simbrief && <p style={{ fontSize: 10, marginTop: 3, marginBottom: 0, marginLeft: 5, opacity: .7 }}>*Opens Simbrief in new window</p>}
                      </div>
                    </div>
                    <div className="uk-grid uk-text-left uk-margin-remove uk-padding-remove" uk-grid="true">
                      <div className="uk-first-column uk-width-auto uk-margin-remove uk-padding-remove">
                        <h4 className="uk-h6 uk-margin-remove">
                          Route
                        </h4>
                        <h3 className="uk-h4 uk-margin-remove">{bid.schedule.route || '-'}</h3>
                      </div>
                    </div>
                    {bid.bidSelected && (
                      <div className="uk-margin-medium-top">
                        <div style={{ backgroundColor: '#C7CFD7', height: 40, width: '100%' }}></div>
                        <DocView style={{ width: '100%', height: '80vh' }} url={bid.simbrief.files.directory + bid.simbrief.files.pdf.link} id={bid.public_id} />
                      </div>)}
                  </div>
                </div>
              ))
            ) : (
              <>{!loading ?
                <div className="uk-width-1-1">
                  <div className="uk-card uk-padding uk-card-default uk-card-body uk-text-left@m uk-text-center">
                    <div className="uk-grid" uk-grid="true">
                      <div className="uk-width-auto@m uk-width-1-1">
                        <img style={{ width: '125px' }} className="uk-margin-right@m uk-align-center" src={logo}></img>
                      </div>
                      <div className="uk-width-auto@m uk-width-1-1 uk-margin-auto-top uk-margin-auto-bottom">
                        <h3 className="uk-h1 uk-align-center">Oooops, No flights found.</h3>
                      </div>
                    </div>
                  </div>
                </div> : <></>}
              </>
            )}
            {loading ? (
              <>
                <div className="uk-width-1-1">
                  <Skeleton height={145} />
                </div>
                <div className="uk-width-1-1">
                  <Skeleton height={145} />
                </div>
                <div className="uk-width-1-1">
                  <Skeleton height={145} />
                </div>
                <div className="uk-width-1-1">
                  <Skeleton height={145} />
                </div>
              </>
            ) : <></>}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
