import { API_URL, SSO_URL, CARRIER_ID } from "../config.js";
// @ts-ignore
import { isJwtExpired } from "jwt-check-expiration";

// Login for access and refresh token
export const login = async (
  username: string,
  password: string
): Promise<{ success?: boolean; detail?: string }> => {
  const req = await fetch(`${API_URL}auth/login`, {
    method: "POST",
    body: JSON.stringify({ email: username, password: password }),
    credentials: "include",
  });
  const res = await req.json();
  // if (req.status === 200) {
  //   return res;
  // }
  return res;
};

// Register New User
export const register = async (
  username: string,
  email: string,
  password: string,
  confirmPassword: string
): Promise<{ success?: boolean; detail?: string }> => {
  const req = await fetch(`${API_URL}auth/users`, {
    method: "POST",
    body: JSON.stringify({
      username: username,
      email: email,
      password: password,
      confirm_password: confirmPassword,
    }),
    credentials: "include",
  });
  const res = await req.json();
  return res;
};

// Refresh token
export const refresh = async (): Promise<object> => {
  const req = await fetch(`${API_URL}auth/token/new`, {
    method: "POST",
    headers: {
      "x-access-token": localStorage.getItem("_t") || "",
    },
    // *************************************************************TODO: redirect if token does not exist**********************************************************\\
  });
  const res = await req.json();
  if (!res) {
    logout();
    location.reload();
  }
  localStorage.setItem("_t", res.data.access_token);
  return res.data;
};

// Logout
export const logout = (): void => {
  localStorage.removeItem("_t");
  window.location.href = "/";
};

// Request reset
export const requestReset = async (email: string): Promise<object> => {
  const req = await fetch(`${API_URL}auth/recover/send`, {
    method: "POST",
    body: JSON.stringify({ email: email }),
  });
  const res = req.json();
  return res;
};

// Reset
export const reset = async (
  token: string,
  password: string,
  confirmPassword: string
): Promise<object> => {
  const req = await fetch(`${API_URL}auth/recover/confirm`, {
    method: "POST",
    body: JSON.stringify({
      token: token,
      password: password,
      confirm_password: confirmPassword,
    }),
  });
  const res = await req.json();
  return res;
};

// export const isAuthenticated = () => {
//   const getCookieValue = (name: string) => {
//       let result = document.cookie.match("(^|[^;]+)\\s*" + name + "\\s*=\\s*([^;]+)")
//       return result ? result.pop() : ""
//   }

//   if (localStorage.getItem('signed-in') === 'false') {
//     return false;
//   }

//   // Check and see if refresh token cookie exists
//   document.cookie = `refresh_token=check_exists;domain=${COOKIE_DOMAIN};path=/;`;

//   if (document.cookie.indexOf('refresh_token=') === -1) {
//       // If not a http cookie redirect (Can it be read)
//       if (getCookieValue('refresh_token')) {
//           // Remove temp cookie
//           document.cookie = 'refresh_token=; Max-Age=0'
//           return false;
//       }
//       return true;
//   } else {
//       // Remove temporary check exists cookie
//       document.cookie = 'refresh_token=; Max-Age=0'

//       // If cookie still exists run mobile check
//       if (!getCookieValue('refesh_token')) {
//         if (localStorage.getItem('signed-in') === 'true') {
//           return true;
//         }
//       }
//       return false;
//   }
// }

export const isAuthenticated = () => {
  const token = localStorage.getItem("_t");

  // check if jwt token is valid
  if (token) {
    return !isJwtExpired(token);
  }
  return false;
};

export const checkLogin = () => {
  if (!isAuthenticated()) {
    ssoLogin();
  }
};

export const ssoLogin = (): void => {
  const currentPage = (window.location.origin + window.location.pathname)
    .replace("http://", "")
    .replace("https://", "");
  window.location.href = `${SSO_URL}auth/sso?id=${CARRIER_ID}&r=${currentPage}`;
};

export const handleSSOResponse = (token: string): void => {
  localStorage.setItem("_t", token);
  refresh();
  // reload page
  window.location.href = window.location.origin + window.location.pathname;
  // if (isAuthenticated()) {
  //   refresh();
  // } else {
  //   ssoLogin();
  // }
};
