import React from "react";
import { Link } from "react-router-dom";

interface Props {
  title: string;
  subTitle: string;
}

export const HeroSmall: React.FC<Props> = (props) => {
  return (
    <div className="uk-section">
      <div className="uk-container">
        <div className="uk-grid" uk-grid="true">
          <div className="uk-margin-remove-topt@m uk-margin-remove-bottom@m uk-width-1-3@l uk-width-1-2@xl uk-text-center uk-text-left@l uk-text-left@xl uk-first-column">
            <div className="uk-inline uk-width-xlarge">
              <h1 className="uk-h6 uk-margin-remove uk-light">
                {props.subTitle}
              </h1>
              <h1 className="uk-h1 uk-margin-top uk-margin-medium-bottom uk-light">
                {props.title}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
