import React from "react";
import { Link } from "react-router-dom";

export const Feature = (props: { date: string }) => {
  return (
    <div className="uk-container uk-margin-medium-top">
      <div
        className="uk-child-width-expand@s uk-text-left uk-grid uk-grid-match uk-grid-medium"
        uk-grid="true"
      >
        <div>
          <div className="uk-first-column feature-1 uk-padding">
            <div className="uk-inline uk-width-xlarge uk-margin-medium-top uk-margin-medium-bottom">
              <h3 className="uk-h3 uk-margin-top uk-light">
                Check out our new routes!
              </h3>
              <p className="uk-margin-medium-bottom uk-light">
                Avelo has a new destination? We got it! We update our schedules
                weekly.<br></br>
                <br></br> Last updated:{" "}
                {props.date ? new Date(props.date).toLocaleDateString() : "-"}{" "}
                🕐
              </p>
              <div className="uk-grid-medium uk-flex-middle uk-flex-inline uk-grid">
                <div className="uk-first-column">
                  <Link
                    style={{ color: "white" }}
                    className="uk-button uk-button-default uk-light uk-button-large "
                    to="route/changelog"
                  >
                    New Routes
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="feature-2 uk-padding uk-light">
            <div className="uk-inline uk-width-xlarge uk-margin-medium-top uk-margin-medium-bottom">
              <h3 className="uk-h3 uk-margin-top uk-light">
                Custom Flight Tracking
              </h3>
              <p className="uk-margin-medium-bottom uk-light">
                Enjoy our advanced flight tracking solution, Titan Tracker, powered by Rotate Group.
              </p>
              <div className="uk-grid-medium uk-flex-middle uk-flex-inline uk-grid">
                <div className="uk-first-column">
                  <a
                    className="uk-button uk-button-default uk-light uk-button-large"
                    href="https://github.com/Rotate-Group/acars-release/releases/download/v1.1.4/Titan-Tracker-Setup-1.1.4.exe"
                  >
                    Get Titan Tracker
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="feature-3 uk-padding uk-light">
            <div className="uk-inline uk-width-xlarge uk-margin-medium-top uk-margin-medium-bottom">
              <h3 className="uk-h3 uk-margin-top uk-light">
                Group Flights and Events
              </h3>
              <p className="uk-margin-medium-bottom uk-light">
                We coordinate with online networks like POSCON and VATSIM so
                that our pilots can get the full experience.
              </p>
              <div className="uk-grid-medium uk-flex-middle uk-flex-inline uk-grid">
                <div className="uk-first-column">
                  <a
                    className="uk-button uk-button-default uk-light uk-button-large"
                    href="https://discord.gg/Z7Tacbr7wu"
                    rel="noreferrer"
                  >
                    Join Discord
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
