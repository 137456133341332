import React, { useContext, useEffect } from "react";
import { Navbar } from "../components/navbar/Navbar.jsx";
import { HeroSmall } from "../components/hero/HeroSmall";
import { Footer } from "../components/footer/Footer.jsx";
import { UserContext } from "../UserContext";
import { isAuthenticated, refresh } from "../middleware/auth";
import { getUserInfo } from "../middleware/core";

export const Page404 = () => {
  const {userContextData, setUserContextData} = useContext(UserContext);

  useEffect(() => {
    const getInitialData = async () => {
      if (isAuthenticated()) {
        const user = await refresh();
        const userInfo = await getUserInfo(user.public_id);
        user.info = userInfo;
        setUserContextData(user);
      }
    }

    getInitialData()
  }, []);

  return (
    <>
      <div className="section-intro">
        <Navbar user={userContextData} />
        <HeroSmall title="404" subTitle="Error - Not Found" />
      </div>
      <Footer />
    </>
  );
};
