// export const API_URL = "http://67.4.212.216:8001";

export let API_URL = process.env.NODE_ENV === 'development' ?
    //'http://localhost:8787/'
    'https://api.rotate.group/'
    : 'https://api.rotate.group/'

export const SSO_URL = 'https://sso.rotate.group/';
export const DISCORD_SERVICE_URL = 'https://discord.rotate.group/';
export const DISCORD_GUILD = '';
export const CARRIER_ID = "d6bdc565-8d8d-4a3c-9796-af5884a8fbb5";
