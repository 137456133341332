import React, { useState, useContext, useEffect } from "react";
import { useParams, Link } from 'react-router-dom';
import { Navbar } from "../components/navbar/Navbar.jsx";
import { HeroSmall } from "../components/hero/HeroSmall";
import { Footer } from "../components/footer/Footer.jsx";
import { UserContext } from "../UserContext";
import { isAuthenticated, refresh } from "../middleware/auth";
import {
  getUserInfo,
  getUserInfoByUsername,
  uploadUserBanner,
  getAirports,
  getDiscordConnectLink,
  removeDiscord,
  transferBase,
  setUserInfoData,
  getSimbriefId
} from "../middleware/core";

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import TotalFlights from "../components/stats/TotalFlights.jsx";

export const Profile = () => {
  const { username } = useParams();
  const [stats, setStats] = useState([])

  const [loading, setLoading] = useState(true);

  const { userContextData, setUserContextData } = useContext(UserContext);
  const [isCurrentUser, setIsCurrentUser] = useState(false);
  const [airportsMap, setAiportsMap] = useState(null);
  const [allBases, setAllBases] = useState(null)
  const [schedulesMap, setSchedulesMap] = useState(null);
  const [schedules, setSchedules] = useState([]);
  const [flights, setFlights] = useState(null);
  const [error, setError] = useState();
  const [alertText, setAlertText] = useState();
  const [avatarURL, setAvatarURL] = useState();
  const [userInfo, setUserInfo] = useState();
  const [discordInfo, setDiscordInfo] = useState();
  const [crewBaseAlertText, setCrewBaseAlertText] = useState();

  // Form
  const [baseValue, setBaseValue] = useState(null);
  const [simbriefId, setSimbriefId] = useState(null);

  useEffect(() => {
    const getInitialData = async () => {
      if (isAuthenticated()) {
        let newAirportsMap = {};
        let newSchedulesMap = {};
        let avatar = 'https://rotate-group.b-cdn.net/user-images/default.jpg';
        let profileUserInfo;

        const user = await refresh();
        const [refreshUserInfo, userInfoData] = await Promise.all([await getUserInfo(user.public_id), await getUserInfoByUsername(username)])


        if (userInfoData.hasOwnProperty('avatar_url')) avatar = userInfoData.avatar_url;

        if (userInfoData['public_id'] == user['public_id']) {
          setIsCurrentUser(true);

          const [simbriefId, discordInfo] = await Promise.all([
            getSimbriefId(),
            getDiscordConnectLink(user.access_token)
          ]);
          if (simbriefId) setSimbriefId(simbriefId.simbrief_id);
          setBaseValue(userInfoData.bases[0] && userInfoData.bases[0].icao);
          setDiscordInfo(discordInfo);
        }

        setAvatarURL(`${avatar}`); //https://flyvfrontier.b-cdn.net/
        setUserContextData({ ...user, info: refreshUserInfo });

        // Get users flights
        const airports = await getAirports();

        // Create Airports Map
        airports.forEach(airport => {
          newAirportsMap[airport.icao] = airport;
        })

        // Create Schedules Map
        userInfoData.flights.forEach(flight => {
          newSchedulesMap[flight.schedule.public_id] = flight.schedule;
        })

        // Sort Flights
        const sortedFlights = userInfoData.flights.sort((a, b) => b.dep_time > a.dep_time ? 1 : -1);
        userInfoData.flights = sortedFlights;

        // Get Airports
        //const airports = await getAirports();
        const bases = airports.filter(a => a.base === true);

        setAllBases(bases);

        // Set state
        setAiportsMap(newAirportsMap);
        setSchedulesMap(newSchedulesMap);
        setFlights(userInfoData.flights);
        setUserInfo(userInfoData);

        // setStats(data);
        setLoading(false);
      }
    }

    getInitialData()
  }, [username]);

  const showSuccessAlert = (text) => {
    setAlertText(text);
    setError(false)
    setTimeout(() => {
      setAlertText(null);
    }, 5000);
  }

  const savePilotData = async (e) => {
    e.preventDefault();
    const updatedUser = await setUserInfoData(userContextData.public_id, { simbriefId })
    if (updatedUser) {
      setUserContextData({ ...userContextData, ...updatedUser });
      showSuccessAlert('User Info Updated');
    } else {
      setError('Error Updating User Info');
    }
  }

  const uplodadBanner = async (e) => {
    e.preventDefault()
    let data = new FormData()
    data.append('avatar', e.currentTarget.files[0])

    const req = await uploadUserBanner(data, userContextData.access_token);
    setAlertText(req.message);
    if (req.message.includes('Error')) {
      setError(true)
    } else {
      setError(false);
      setTimeout(() => {
        setAvatarURL(`${req.url}`)//https://flyvfrontier.b-cdn.net/
      }, 1000)
    }
  }

  return (
    <>
      <div className="section-intro">
        <Navbar user={userContextData} />
        <HeroSmall title={username} subTitle="Profile" />
      </div>
      <div className="uk-section uk-padding-medium">
        <div className="uk-container">
          {alertText ? (
            <div className={error ? "uk-alert-danger" : "uk-alert-success"} uk-alert="true">
              <a className="uk-alert-close" uk-close="true" />
              <p>{alertText}</p>
            </div>)
            :
            <></>}
          <div className="uk-grid-small flights-stats" uk-grid="true">
            {loading ? (
              <>
                <div className="uk-width-1-3@m">
                  <Skeleton height={290} />
                </div>
                <div className="uk-width-2-3@m">
                  <Skeleton height={290} />
                </div>
              </>
            ) :
              <>
                <div className="uk-width-1-3@m">
                  <form>
                    <div style={{ height: '300px', position: 'relative', boxShadow: '0 5px 15px rgb(0 0 0 / 18%)' }}>
                      <div className="uk-card uk-card-primary uk-card-body" style={{ height: '100%', background: `url("${avatarURL}") 0% 0% / cover`, boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.5), 0 5px 15px rgb(0 0 0 / 18%)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                        {isCurrentUser ? <a uk-form-custom="true" id="edit-image-marker" className="uk-text-truncate uk-position-absolute uk-transform-center" style={{ left: '80%', top: '80%', maxWidth: 40 }} href="#" uk-marker="true" uk-tooltip="Change profile image">
                          <input onChange={e => uplodadBanner(e)} type="file" />
                          <span className="uk-icon" uk-icon="pencil"></span>
                        </a> : <></>}
                      </div>
                    </div>
                  </form>
                  {isCurrentUser && Object(userInfo) ? (
                    <>
                      <div className="uk-margin-medium-top" style={{ height: '300px', position: 'relative', boxShadow: '0 5px 15px rgb(0 0 0 / 18%)' }}>
                        <div className="uk-card uk-card-primary uk-card-body uk-text-left bg-image" style={{ height: '100%', background: 'url("../images/stats-8.jpg")', boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.5), 0 5px 15px rgb(0 0 0 / 18%)', backgroundSize: 'cover' }}>
                          <p className="uk-text-left uk-margin-remove uk-text-large">Crew Base</p>
                          <h3 className="uk-card-title uk-text-left uk-margin-remove uk-text-bold">{userInfo.bases[0] ? userInfo.bases[0].icao : '-'}</h3>
                          <br></br>
                          <button
                            className="uk-button uk-button-default"
                            href="#modal-sections"
                            uk-toggle="true"
                            id="request-transfer-button"
                            onClick={e => {
                              UIkit.modal(document.querySelector('#modal-sections')).show();
                              e.currentTarget.hidden = false;
                            }}>Request a transfer</button>

                          <div id="modal-sections" uk-modal="true">
                            <div className="uk-modal-dialog">
                              <button className="uk-modal-close-default" type="button" uk-close="true" />
                              {crewBaseAlertText && (
                                <div className="uk-alert-danger" uk-alert="true">
                                  <p>{crewBaseAlertText}</p>
                                </div>)}
                              <div className="uk-modal-header">
                                <h2 className="uk-modal-title">Transfer Request</h2>
                              </div>
                              <div className="uk-modal-body">
                                <p>Select from the available Crew Bases below. Remember you are unable to transfer if you are currently holding a trip. Each Crew Base shows the number remaining slots.</p>
                                {allBases ?
                                  allBases.map(airport =>
                                    <button
                                      key={airport.icao}
                                      className={(baseValue ? baseValue : '-') === airport.icao ? 'uk-button uk-button-primary uk-margin-small-right uk-margin-small-top' : 'uk-button uk-button-default uk-margin-small-right uk-margin-small-top'}
                                      type="button"
                                      data-icao={airport.icao}
                                      onClick={e => setBaseValue(e.currentTarget.getAttribute('data-icao'))}>{airport.name} ({airport.icao})</button>
                                  )
                                  : <></>}
                              </div>
                              <div className="uk-modal-footer uk-text-right">
                                <button className="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button">Cancel</button>
                                <button className="uk-button uk-button-primary" type="button"
                                  onClick={async (e) => {
                                    const base = await transferBase(airportsMap[baseValue].public_id)
                                    if (base?.public_id) {
                                      let newUserInfo = {
                                        ...userInfo,
                                        bases: [
                                          { icao: baseValue }
                                        ]
                                      };
                                      setUserInfo(newUserInfo);
                                      UIkit.modal(document.querySelector('#modal-sections')).hide();
                                      setError(false)
                                      setAlertText("Crew Base Transfer Successful!")
                                    } else {
                                      setCrewBaseAlertText(base)
                                      setTimeout(() => {
                                        setCrewBaseAlertText(null)
                                      }, 5000)
                                    }
                                  }}>Save</button>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>

                      <div className="uk-margin-medium-top" style={{ height: '300px', position: 'relative', boxShadow: '0 5px 15px rgb(0 0 0 / 18%)' }}>
                        <div className="uk-card uk-card-primary uk-card-body uk-text-left bg-image" style={{ height: '100%', background: 'url("../images/discord.jpg")', boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.5), 0 5px 15px rgb(0 0 0 / 18%)', backgroundSize: 'cover', backgroundPosition: 'right top' }}>
                          <p className="uk-text-left uk-margin-remove uk-text-large">Discord</p>
                          <h3 className="uk-card-title uk-text-left uk-margin-remove uk-text-bold">{discordInfo?.user ? discordInfo.user.username : 'NOT LINKED'}</h3>
                          {discordInfo?.user ? <p className=" uk-text-large" style={{ marginTop: 0 }}>#{discordInfo.user.discriminator}</p> : <></>}
                          <br></br>

                          {discordInfo?.link ?
                            (<a
                              target="_blank"
                              className="uk-button uk-button-default"
                              href={discordInfo?.link}
                              uk-toggle="true"
                              style={{ background: '#fff', color: '#000' }}
                            >Connect Discord</a>)
                            :
                            (discordInfo?.user ? <button
                              className="uk-button uk-button-default"
                              uk-toggle="true"
                              style={{ background: '#fff', color: '#000' }}
                              onClick={async (e) => {
                                const res = await removeDiscord(userContextData.access_token)
                                if (res) setDiscordInfo(null);
                                const discordInfo = await getDiscordConnectLink(userContextData.access_token);
                                setDiscordInfo(discordInfo.link);
                              }}>Remove Discord</button> : (<>Discord Service Error</>))
                          }

                        </div>
                      </div>
                    </>)
                    : <></>
                  }


                </div>
                <div className="uk-width-2-3@m">
                  {
                    isCurrentUser && (
                      <div className="uk-card uk-card-default uk-padding-small uk-margin-medium-bottom">
                        <h3 className="uk-heading-line uk-text-left uk-padding-small uk-margin-remove-bottom"><span>Account Details</span></h3>
                        <form className="uk-form-stacked uk-margin-small-top uk-margin-small-left" onSubmit={e => savePilotData(e)}>
                          <fieldset className="uk-fieldset uk-grid">
                            <div className="uk-width-1-1 uk-margin ">
                              <div className="uk-margin uk-width-1-2">
                                <label className="uk-form-label uk-align-left">Simbrief ID / Username</label>
                                <input id="simbriefId" className="uk-input" type="text" placeholder="" value={simbriefId} onInput={e => setSimbriefId(e.target.value)} />
                              </div>
                            </div>
                            <div className="uk-width-1-1">
                              <button
                                className="uk-button uk-button-primary uk-align-right"
                                id="set-pilot-data"
                                type="submit"
                              >Save</button>
                            </div>

                          </fieldset>
                        </form>
                      </div>
                    )
                  }


                  <div className="uk-card uk-card-default uk-padding-small">
                    <h3 className="uk-heading-line uk-text-left uk-padding-small uk-margin-remove-bottom"><span>Completed Flights {flights.length > 0 ? `(${flights.length})` : ''}</span></h3>
                    <table className="uk-table uk-table-middle uk-table-divider uk-table-hover uk-table-responsive uk-margin-remove-top">
                      <thead>
                        <tr>
                          <th className="uk-width-small">Flight #</th>
                          <th>Dep ICAO</th>
                          <th>Arr ICAO</th>
                          <th>Block Flight Time</th>
                          <th>Equipment</th>
                          <th>Status</th>
                          <th>Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        {flights.map(flight => (
                          <tr>
                            <td>{schedulesMap[flight.schedule_id].callsign}{schedulesMap[flight.schedule_id].flight_number}</td>
                            <td>{schedulesMap[flight.schedule_id].dep_icao}</td>
                            <td>{schedulesMap[flight.schedule_id].arr_icao}</td>
                            <td>{flight.flight_time ? flight.flight_time.toFixed(1) : '-'}</td>
                            <td>{flight.aircraft_type}</td>
                            <td>{flight.flight_time ? (flight.approved ? <span className="uk-label uk-label-success">Approved</span> : <span className="uk-label uk-label-danger">Rejected</span>) : <span className="uk-label uk-label-info">In Progress</span>}</td>
                            <td>{flight.completed ? <Link to={`/route/detail/${flight.public_id}`} className="uk-button uk-button-default" type="button" disable="true">Details</Link> : <></>}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
