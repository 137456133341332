import React, { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
} from "react-leaflet";
import L from "leaflet";
import { Link } from "react-router-dom";

export const HeroFlightDetail = ({
  flight,
  airports,
  positionReports,
  title,
  subTitle,
  center,
}) => {
  const [map, setMap] = useState(null);
  const URL =
    "https://api.mapbox.com/styles/v1/edwinbrowwn/cl7unssvw000m14o9g2oacpyb/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZWR3aW5icm93d24iLCJhIjoiY2trcWs0YWZjMDg0bzJ1cXQyODB1NnNydSJ9.tlLwEw7sB5aNaHxQTpqkRw";

  const svgIcon = L.divIcon({
    html: `
          <svg
            width="50"
            height="50"
            viewBox="0 0 100 100"
            version="1.1"
            preserveAspectRatio="none"
            stroke="#1e87f0"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle r="5" cx="10" cy="10" fill="orange" />
          </svg>`,
    className: "",
    iconSize: [50, 50],
    iconAnchor: [5, 5],
  });

  const svgIcon2 = L.divIcon({
    html: `
          <svg
            width="50"
            height="50"
            viewBox="0 0 100 100"
            version="1.1"
            preserveAspectRatio="none"
            stroke="#1e87f0"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle r="5" cx="10" cy="10" fill="#fff" />
          </svg>`,
    className: "",
    iconSize: [50, 50],
    iconAnchor: [5, 5],
  });

  useEffect(() => {
    if (map) map.flyTo(center);
  }, [center, flight]);

  return (
    // <div className="uk-section" style={{margin: 0, passing: 0}}>
    <div className="full-map-container">
      <MapContainer
        className="uk-section"
        style={{ width: "100%", height: "100%" }}
        center={center}
        zoom={6}
        whenCreated={setMap}
        scrollWheelZoom={true}
      >
        <TileLayer
          // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url={URL}
        />
        {airports ? (
          airports.map((airport) => (
            <Marker
              icon={svgIcon}
              position={[airport.latitude, airport.longitude]}
            >
              <Popup>
                <p>
                  <strong>ICAO:</strong> {airport.icao}
                  <br></br>
                  <strong>Name:</strong> {airport.name}
                </p>
              </Popup>
            </Marker>
          ))
        ) : (
          <></>
        )}
        {positionReports.length > 0 ? (
          <Polyline
            key={1}
            positions={positionReports}
            color={"#1e87f0"}
            weight={"2"}
          />
        ) : (
          <></>
        )}
      </MapContainer>
      <div
        className="uk-section"
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          left: 0,
          zIndex: 999,
          background: "rgba(0,0,0,.45)",
          pointerEvents: "none",
          height: "100%",
        }}
      >
        <div className="uk-container">
          <div className="uk-grid" uk-grid="true">
            {flight ? (
              <div
                className="uk-child-width-1-2@m"
                uk-grid="true"
                style={{ pointerEvents: "all" }}
              >
                <div>
                  <div
                    className="uk-card uk-card-default"
                    style={{ width: 400, maxWidth: "calc(100vw - 30px)" }}
                  >
                    <div
                      className="uk-card-media-top bg-image"
                      style={{
                        width: "100%",
                        height: 200,
                        backgroundPosition: "center, center, center",
                        background: ` url("../../images/types/${flight.aircraft_type}.jpg") center`,
                        backgroundSize: "cover",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: 200,
                          backgroundPosition: "center, center, center",
                          background: `linear-gradient(to top, #f0f1f3 5%, rgba(221, 221, 221, 0))`,
                          backgroundSize: "cover",
                        }}
                      ></div>
                    </div>
                    <div
                      className="uk-card-body uk-text-left uk-padding-remove-top"
                      style={{ overflowY: "auto", maxHeight: "45vh" }}
                    >
                      <div
                        className="uk-grid-small uk-child-width-1-2"
                        uk-grid="true"
                      >
                        <div>
                          <h2 className="uk-card-title uk-heading-large ">
                            {flight.schedule.callsign}
                            {flight.schedule.flight_number}
                          </h2>
                        </div>
                        <div>
                          <h5 className="uk-padding-remove-top uk-margin-remove-top">
                            Aircraft Type:<br></br>
                            {flight.aircraft_type}
                          </h5>
                        </div>
                      </div>
                      <div
                        className="uk-grid-small uk-child-width-1-2"
                        uk-grid="true"
                      >
                        <div>
                          <h5>
                            Cruise Altitude:<br></br>
                            {flight.schedule.altitude}
                          </h5>
                        </div>
                        <div>
                          <h5>
                            Notes:<br></br>
                            {flight.schedule.notes}
                          </h5>
                        </div>
                      </div>

                      <br></br>

                      <div>
                        <div>
                          <div className="uk-tile uk-tile-muted uk-padding-small">
                            <p className="uk-margin-remove-bottom uk-padding-remove-bottom">
                              {airports[0].icao} - {airports[0].name}
                            </p>
                            <p className="uk-text-italic uk-padding-remove-top uk-margin-remove-top">
                              {new Date(flight.dep_time).toLocaleTimeString(
                                undefined,
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      <br></br>
                      <div>
                        <div>
                          <div className="uk-tile uk-tile-muted uk-padding-small">
                            <p className="uk-margin-remove-bottom uk-padding-remove-bottom">
                              {airports[1].icao} - {airports[1].name}
                            </p>
                            <p className="uk-text-italic uk-padding-remove-top uk-margin-remove-top">
                              {new Date(flight.arr_time).toLocaleTimeString(
                                undefined,
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      {flight.logItems ? (
                        <>
                          <br></br>
                          <div>
                            <div>
                              <h5>Log:</h5>
                              <div className="uk-tile uk-tile-muted uk-padding-small">
                                <div style={{ "overflow-y": "auto" }}>
                                  {flight.logItems.map((item) => (
                                    <p className="uk-margin-small uk-text-small">
                                      <span className="uk-text-italic">
                                        {item[0]}:{" "}
                                      </span>
                                      {item[1]}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};
