import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { isAuthenticated } from "./middleware/auth";

import { UserContext } from "./UserContext";


export const RedirectAuth = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={props => {
            // If authenticated redirect to home page
            if (isAuthenticated() !== false) {
                return (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: {
                                from: props.location
                            }
                        }}
                    />
                )
            }
            return (<> <Component {...props} /> </>)
        }} />
    );
};

export const ProtectedRoute = ({ component: Component, ...rest }) => {
    const { userContextData, setUserContextData } = useContext(UserContext);
    return (
        <Route {...rest} render={props => {
            // If authenticated redirect to home page
            if (!isAuthenticated() !== false || userContextData === {}) {
                return (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: {
                                from: props.location
                            }
                        }}
                    />
                )
            }
            return (<> <Component {...props} /> </>)
        }} />
    );
};