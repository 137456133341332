import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

const NavRibbon = ({ credits, location, loading }) => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const clock = setInterval(() => setTime(new Date()), 1000);
    return () => {
      clearInterval(clock);
    };
  }, []);

  if (loading) {
    return (
      <>
        <div className="nav-ribbon">
          <div className="uk-container">
            <div className="nav-ribbon-info">
              <span>
                <Skeleton
                  width={200}
                  highlightColor="rgba(2, 131, 231)"
                  baseColor="rgba(3, 102, 180, 1)"
                />
              </span>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="nav-ribbon">
        <div className="uk-container">
          <div className="nav-ribbon-info">
            <span>Current Location: {!location ? "-" : location}</span>
            {/* <span>|</span>
            <span>v${!credits ? "-" : credits}</span> */}
            <span>|</span>
            <span>
              {time.getUTCMonth() + 1}/{time.getUTCDate()}{" "}
              {time.getUTCHours().toString().length === 1
                ? `0${time.getUTCHours()}`
                : time.getUTCHours()}
              :
              {time.getUTCMinutes().toString().length === 1
                ? `0${time.getUTCMinutes()}`
                : time.getUTCMinutes()}{" "}
              Z
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavRibbon;
