import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { reset } from "../middleware/auth"

export const Reset = (props) => {
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  const [resetToken, setResetToken] = useState();

  const submitForm = async () => {
    const req_reset = await reset(resetToken, password, confirmPassword);
    if (req_reset.success) {
      setError(null);
      setSuccess(req_reset.detail)
    }
    if (!req_reset.success) setError(req_reset.detail);
  }

  useEffect(() => {
    if (token) {
      setResetToken(token);
    } else {
      props.history.push('/');
    }
  }, [token]);

  return (
    <>
      <div className="uk-section login-section uk-padding-medium">
        <div className="uk-container">
          <div className="uk-grid uk-flex-center" uk-grid="true">
            <div className="uk-flex-first uk-width-1-2@s uk-width-1-1 uk-text-left uk-first-column">
              <div className="uk-card uk-card-default uk-padding-large uk-text-center">
                <h1 className="sky">
                  <img
                    src="../images/fft-icon.jpg"
                    className="uk-border-circle crew-logo"
                    alt="vfrontier crew icon"
                  ></img>
                  Password Reset
                </h1>
                <form
                  className="uk-grid-small uk-grid-match uk-grid"
                  uk-grid="true"
                  onSubmit={(e) => {
                    e.preventDefault();
                    submitForm();
                  }}>
                  <div className="uk-width-1-1">
                    <div className="uk-position-relative uk-margin-medium">
                      {error ? (
                        <div className="uk-alert-danger" uk-alert="true">
                          <a className="uk-alert-close" uk-close="true" />
                          <p>{error}</p>
                        </div>)
                        :
                        <></>}
                      {success ? (
                        <div className="uk-alert-success" uk-alert="true">
                          <a className="uk-alert-close" uk-close="true" />
                          <Link to="/login">{success}. Return to login</Link>
                        </div>)
                        :
                        <></>}
                    </div>
                  </div>
                  <div className="uk-width-1-1 uk-margin-bottom-small">
                    <input
                      className="uk-input uk-input-large"
                      type="password"
                      placeholder="Password"
                      onInput={(e) => setPassword(e.currentTarget.value)}
                      required
                    />
                  </div>
                  <div className="uk-width-1-1 uk-margin-bottom">
                    <input
                      className="uk-input uk-input-large"
                      type="password"
                      placeholder="Confirm Password"
                      onInput={(e) => setConfirmPassword(e.currentTarget.value)}
                      required
                    />
                  </div>
                  <div className="uk-width-1-1 uk-width-1-1">
                    <button className="uk-button uk-button-primary uk-button-large">
                      Reset Password
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
