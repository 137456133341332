import React, { useState, useContext, useEffect } from "react";
import { Navbar } from "../components/navbar/Navbar.jsx";
import { HeroFlightDetail } from "../components/hero/HeroFlightDetail.jsx";
import { Footer } from "../components/footer/Footer.jsx";
import { Link, useParams } from "react-router-dom";

import { UserContext } from "../UserContext";
import { isAuthenticated, refresh } from "../middleware/auth";
import { getUserInfo, getFlightsById, getAirports, getPositionReports } from "../middleware/core";

import Skeleton from 'react-loading-skeleton';

export const RouteDetails = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  const [userStats, setUserStats] = useState(null);
  const [flight, setFlight] = useState(null);
  const [positionReports, setPositionReports] = useState([]);
  const [airports, setAirports] = useState([]);
  const [center, setCenter] = useState([40, -110])

  const { userContextData, setUserContextData } = useContext(UserContext);

  useEffect(() => {
    const getInitialData = async () => {
      if (isAuthenticated()) {
        // Get user data
        const user = await refresh();
        const userInfo = await getUserInfo(user.public_id);
        user.info = userInfo;
        setUserStats(userInfo.stats);
        const posReps = [];

        const airport_list = await getAirports();

        const current_flight = await getFlightsById(id);


        const position_reports = await getPositionReports(id);
        position_reports.sort((a, b) => a.date_time < b.date_time ? 1 : -1);

        position_reports.forEach((rep, i) => {
          posReps.push([rep.latitude, rep.longitude]);
        })
        setPositionReports(posReps);
        console.log(current_flight)
        const schedule_req = current_flight.schedule
        const a = [];
        airport_list.forEach(airP => {
          if (schedule_req.dep_icao == airP.icao) a[0] = airP;
          if (schedule_req.arr_icao == airP.icao) a[1] = airP;
        })
        setAirports(a);

        const posMidIndx = position_reports.length / 2;

        const middle = position_reports[posMidIndx.toFixed()];

        current_flight.schedule = schedule_req

        let logItems = [];
        if (current_flight.log) {
          JSON.parse(current_flight.log).forEach(event => {
            logItems.push([[new Date(event.time).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })], [event.event]]);
          })
        }
        current_flight.logItems = logItems;

        setFlight(current_flight);
        setCenter([middle.latitude - 2, middle.longitude - 8]);

        setUserContextData(user);

      }

      setLoading(false);
    }

    getInitialData()
  }, []);

  return (
    <>
      <div className="section-intro" style={{ zIndex: 995 }}>
        <Navbar user={userContextData} />
      </div>

      {/* Full screen map */}
      <HeroFlightDetail title="Flight Reports" subTitle="Crew Center" flight={flight} airports={airports} positionReports={positionReports} center={center} />

      <div className="uk-section uk-padding-medium">
        <div className="uk-container">
          {loading ? (
            <div className="uk-width-1-1">
              <Skeleton height={290} />
            </div>
          ) : <></>}
        </div>
      </div>
      <Footer small={true} />
    </>
  );
};
