import React, { useContext, useEffect, useState } from 'react';
import { Footer } from '../components/footer/Footer';
import { Navbar } from "../components/navbar/Navbar.jsx";
import { HeroSmall } from "../components/hero/HeroSmall";

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { Link } from 'react-router-dom';

import { UserContext } from "../UserContext";
import { isAuthenticated, refresh } from "../middleware/auth";
import { getAllUsers, getUserInfo } from '../middleware/core';

export const Roster = () => {
    const { userContextData, setUserContextData } = useContext(UserContext);
    const [pilots, setPilots] = useState([])

    useEffect(() => {
        const getInitialData = async () => {
            if (isAuthenticated()) {
                const user = await refresh();
                const userInfo = await getUserInfo(user.public_id);
                user.info = userInfo;
                setUserContextData(user);
            }

            let pilotsReq = await getAllUsers();
            pilotsReq = pilotsReq.filter(p => p.approved !== true)
            pilotsReq = pilotsReq.sort(function (a, b) {
                var nameA = a.username.toUpperCase();
                var nameB = b.username.toUpperCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
            });
            pilotsReq = pilotsReq.sort(p => p.active ? -1 : 1)
            setPilots(pilotsReq)

        }

        getInitialData()
    }, [])

    return (
        <>
            <div className="section-intro">
                <Navbar user={userContextData} />
                <HeroSmall title="Pilot Roster" subTitle="FlyvFrontier" />
            </div>
            <div className="uk-section uk-padding-medium">
                <div className="uk-container">
                    <div className="uk-card uk-card-default uk-padding-small">
                        <h3 className="uk-heading-line uk-text-left uk-padding-small uk-margin-remove-bottom"><span>{pilots.length} Total Pilots</span></h3>
                        <ul uk-accordion="true">
                            {pilots.length > 0 ? (
                                <table className="uk-table uk-text-left uk-table-middle uk-table-divider uk-table-hover uk-table-responsive uk-margin-remove-top">
                                    <thead>
                                        <tr>
                                            <th> </th>
                                            <th>Username</th>
                                            <th>Rank</th>
                                            <th>Crew Base</th>
                                            <th>Status</th>
                                            <th>Profile Link</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pilots.map((p, i) => (
                                            <tr key={p.id}>
                                                <td>{i + 1}</td>
                                                <td>{p?.first_name}{' '}({p.username})</td>
                                                <td>{p?.ranks?.length ? p.ranks[0]?.rank : 'New Hire'}</td>
                                                <td>{p.bases[0] ? p.bases[0].icao : '-'}</td>
                                                <td>{p.active ? <span className="uk-label uk-label-success">Active</span> : (p.active === false ? <span className="uk-label uk-label-danger">Inactive</span> : <span className="uk-label uk-label-info">New Hire</span>)}</td>
                                                <td><Link to={`profile/${p.username}`} className="uk-button uk-button-default" type="button" disable="true">Profile</Link></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (<>
                                <div className="uk-width-1-1">
                                    <Skeleton height={80} />
                                </div>
                                <div className="uk-width-1-1">
                                    <Skeleton height={80} />
                                </div>
                                <div className="uk-width-1-1">
                                    <Skeleton height={80} />
                                </div>
                                <div className="uk-width-1-1">
                                    <Skeleton height={80} />
                                </div>
                            </>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}