import {
  API_URL,
  CARRIER_ID,
  DISCORD_GUILD,
  DISCORD_SERVICE_URL,
} from "../config.js";
import { xml2json, parseXml } from "../utils.js";
import { bidLineQueryType } from "./types.js";

export const getCarrierInfo = async () => {
  const req = await fetch(`${API_URL}carrier?carrierId=${CARRIER_ID}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": localStorage.getItem("_t") || "",
    },
  });
  const res = await req.json();
  return res.data;
};

export const getUserInfo = async (
  userId: string
): Promise<{ success?: boolean; detail?: string }> => {
  const options = {
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("_t") || "",
    },
  };
  const req = await fetch(
    `${API_URL}users/${userId}?carrierId=${CARRIER_ID}`,
    options
  );
  const res = await req.json();
  return res.data;
};

export const setUserInfoData = async (
  userId: string,
  data: any
): Promise<{ success?: boolean; detail?: string }> => {
  const options = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "x-access-token": localStorage.getItem("_t") || "",
      "Content-Type": "application/json",
    },
  };
  const req = await fetch(`${API_URL}users/${userId}`, options);
  const res = await req.json();
  return res.data;
};

export const getSimbriefId = async (): Promise<{
  success?: boolean;
  detail?: string;
}> => {
  const options = {
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("_t") || "",
    },
  };
  const req = await fetch(`${API_URL}users/simbrief`, options);
  const res = await req.json();
  return res.data;
};

export const getSchedules = async (data: any = null): Promise<[]> => {
  const options = {
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("_t") || "",
    },
  };
  const params = [
    data?.dep ? `&dep=${data.dep}` : "",
    data?.arr ? `&arr=${data.arr}` : "",
    data?.aircraftType ? `&aircraftType=${data.aircraftType}` : "",
    data?.flightTimeGt
      ? `&flightTimeGt=${data.flightTimeGt.replace(/\D/g, "")}`
      : "",

    data?.flightTimeLt
      ? `&flightTimeLt=${data.flightTimeLt.replace(/\D/g, "")}`
      : "",
  ].join("");
  const req = await fetch(
    `${API_URL}schedules?carrierId=${CARRIER_ID}${params}`,
    options
  );
  const res = await req.json();
  return res.data;
};

export const getSchedulesLast = async (): Promise<[]> => {
  const options = {
    method: "GET",
  };
  const req = await fetch(
    `${API_URL}schedules/changelog/time?carrierId=${CARRIER_ID}`,
    options
  );
  const res = await req.json();
  return res.data;
};

export const getStats = async (): Promise<{}> => {
  const options = {
    method: "GET",
    // headers: {
    //   credentials: "include"
    // }
  };
  const req = await fetch(`${API_URL}stats?carrierId=${CARRIER_ID}`, options);
  const res = await req.json();
  return res;
};

export const getAirports = async (): Promise<[]> => {
  const options = {
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("_t") || "",
    },
  };
  const req = await fetch(
    `${API_URL}airports?carrierId=${CARRIER_ID}`,
    options
  );
  const res = await req.json();
  return res.data;
};

export const getBids = async (): Promise<[]> => {
  const options = {
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("_t") || "",
    },
  };
  const req = await fetch(`${API_URL}bids?carrierId=${CARRIER_ID}`, options);
  const res = await req.json();
  return res.data;
};

export const deleteBid = async (bidId: string): Promise<any> => {
  const options = {
    method: "DELETE",
    headers: {
      "x-access-token": localStorage.getItem("_t") || "",
    },
  };
  const req = await fetch(`${API_URL}bids/${bidId}`, options);
  const res = await req.json();
  return res;
};

// export const getFlights = async (userId: string): Promise<[]> => {
//   const options = {
//     method: "GET",
//     // headers: {
//     //   credentials: "include"
//     // }
//   };
//   const req = await fetch(`${API_URL}flights/user/${userId}`, options);
//   const res = await req.json();
//   return res;
// };

export const getFlightsById = async (flightId: string): Promise<[]> => {
  const options = {
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("_t") || "",
    },
  };
  const req = await fetch(`${API_URL}flight/${flightId}`, options);
  const res = await req.json();
  return res.data;
};

export const getPositionReports = async (flightId: string): Promise<[]> => {
  const options = {
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("_t") || "",
    },
  };
  const req = await fetch(`${API_URL}reports/${flightId}`, options);
  const res = await req.json();
  return res.data;
};

export const getScheduleById = async (scheduleId: string): Promise<[]> => {
  const options = {
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("_t") || "",
    },
  };
  const req = await fetch(`${API_URL}schedule/${scheduleId}`, options);
  const res = await req.json();
  return res;
};

export const createBid = async (scheduleId: string): Promise<any> => {
  const options = {
    method: "POST",
    body: JSON.stringify({ scheduleId }),
    headers: {
      "x-access-token": localStorage.getItem("_t") || "",
      "content-type": "application/json",
    },
  };
  const req = await fetch(`${API_URL}bids`, options);
  const res = await req.json();
  return res;
};

export const getLastRouteDate = async (): Promise<[]> => {
  const options = {
    method: "GET",
    // headers: {
    //   credentials: "include"
    // }
  };
  const req = await fetch(`${API_URL}schedules/last/time`, options);
  const res = await req.json();
  return res;
};

export const sendContactForm = async (
  firstName: string,
  lastName: string,
  email: string,
  reason: string,
  message: string
): Promise<[]> => {
  const options = {
    method: "POST",
    body: JSON.stringify({
      first_name: firstName,
      last_name: lastName,
      email: email,
      reason: reason,
      message: message,
    }),
    // headers: {
    //   credentials: "include"
    // }
  };
  const req = await fetch(`${API_URL}contact`, options);
  const res = await req.json();
  return res;
};

export const getUserInfoByUsername = async (username: string): Promise<[]> => {
  const options = {
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("_t") || "",
    },
  };
  const req = await fetch(
    `${API_URL}users/name/${username}?carrierId=${CARRIER_ID}`,
    options
  );
  const res = await req.json();
  return res.data;
};

export const getAllUsers = async (): Promise<[]> => {
  const options = {
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("_t") || "",
    },
  };
  const req = await fetch(`${API_URL}users?carrierId=${CARRIER_ID}`, options);
  const res = await req.json();
  return res.data;
};

export const uploadUserBanner = async (
  data: FormData,
  accessToken: string
): Promise<[]> => {
  const options = {
    method: "POST",
    body: data,
    headers: {
      "x-access-token": accessToken,
    },
  };
  const req = await fetch(`${API_URL}users/image/upload`, options);
  const res = await req.json();
  return res.data;
};

export const getDiscordConnectLink = async (accessToken: string) => {
  const options = {
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("_t") || "",
    },
  };
  const req = await fetch(`${API_URL}discord/${CARRIER_ID}`, options);
  const res = await req.json();
  return res.data;
};

export const removeDiscord = async (accessToken: string) => {
  const options = {
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("_t") || "",
    },
  };
  const req = await fetch(`${API_URL}discord/remove/${CARRIER_ID}`, options);
  const res = await req.json();
  return res.data;
};

export const transferBase = async (airportId: string) => {
  const options = {
    method: "POST",
    body: JSON.stringify({
      airportId,
      carrierId: CARRIER_ID,
    }),
    headers: {
      "x-access-token": localStorage.getItem("_t") || "",
      "content-type": "application/json",
    },
  };
  const req = await fetch(`${API_URL}users/base`, options);
  const res = await req.json();
  return res.data;
};

export const getSimbriefByBidId = async (bidId: string, simbriefId: string) => {
  let param: string = "";
  const options = {
    method: "GET",
  };
  // Check if simbriefId contains numbers only
  if (simbriefId.match(/^[0-9]+$/)) {
    param = `?userId=${simbriefId}`;
  } else {
    param = `?username=${simbriefId}`;
  }
  const req = await fetch(
    `https://www.simbrief.com/api/xml.fetcher.php?${param}&static_id=${bidId}`,
    options
  );
  const res = parseXml(await req.text());
  const json = xml2json(res);
  return JSON.parse(json.replace("undefined", "")).OFP;
};

export const getBidLine = async () => {
  const options = {
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("_t") || "",
    },
  };
  const req = await fetch(`${API_URL}bids/line/${CARRIER_ID}`, options);
  const res = await req.json();
  return res.data;
};

export const generateBidLine = async (bidLineQuery: bidLineQueryType) => {
  const options = {
    method: "POST",
    body: JSON.stringify({
      ...bidLineQuery,
      carrierId: CARRIER_ID,
    }),
    headers: {
      "x-access-token": localStorage.getItem("_t") || "",
      "content-type": "application/json",
    },
  };
  const req = await fetch(`${API_URL}bids/line/generate`, options);
  const res = await req.json();
  if (res.data.sequence.length < 1) return false;
  return res.data;
};

export const saveBidLine = async (bidLineMap: string[]) => {
  const options = {
    method: "POST",
    body: JSON.stringify({
      map: bidLineMap,
      carrierId: CARRIER_ID,
    }),
    headers: {
      "x-access-token": localStorage.getItem("_t") || "",
      "content-type": "application/json",
    },
  };
  const req = await fetch(`${API_URL}bids/line/save`, options);
  const res = await req.json();
  return res.data;
};

export const deleteBidLIne = async (bidId: string) => {
  const options = {
    method: "DELETE",
    headers: {
      "x-access-token": localStorage.getItem("_t") || "",
    },
  };
  const req = await fetch(`${API_URL}bids/line/${bidId}`, options);
  const res = await req.json();
  return res.data;
};

export const getLatestFlightsByCarrierId = async (limit: number = 10) => {
  const options = {
    method: "GET",
  };
  const req = await fetch(
    `${API_URL}flights/latest?carrierId=${CARRIER_ID}&limit=${limit}`,
    options
  );
  const res = await req.json();
  return res.data;
};

export const getLatestOrPinnedDiscordAnnouncement = async () => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  const req = await fetch(
    `${DISCORD_SERVICE_URL}message/${DISCORD_GUILD}`,
    options
  );
  const res = await req.json();
  return res.message;
};
