import React, { useState } from "react";
import { Link } from "react-router-dom";
import { requestReset } from "../middleware/auth"

export const RequestReset = () => {
  const [email, setEmail] = useState('');

  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  const submitForm = async () => {
    const req_reset = await requestReset(email);
    if (req_reset.success) {
      setError(null);
      setSuccess(req_reset.detail);
    }
    if (!req_reset.success) setError(req_reset.detail);
  }

  return (
    <>
      <div className="uk-section login-section uk-padding-medium">
        <div className="uk-container">
          <div className="uk-grid uk-flex-center" uk-grid="true">
            <div className="uk-flex-first uk-width-1-2@s uk-width-1-1 uk-text-left uk-first-column">
              <div className="uk-card uk-card-default uk-padding-large uk-text-center">
                <h1 className="sky">
                  <img
                    src="../images/fft-icon.jpg"
                    className="uk-border-circle crew-logo"
                    alt="vfrontier crew login icon"
                  ></img>
                  Crew Login
                </h1>
                <form
                  className="uk-grid-small uk-grid-match uk-grid"
                  uk-grid="true"
                  onSubmit={(e) => {
                    e.preventDefault();
                    submitForm();
                  }}>
                  <div className="uk-width-1-1">
                    <div className="uk-position-relative uk-margin-medium">
                      {error ? (
                        <div className="uk-alert-danger" uk-alert="true">
                          <a className="uk-alert-close" uk-close="true" />
                          <p>{error}</p>
                        </div>)
                        :
                        <></>}
                      {success ? (
                        <div className="uk-alert-success" uk-alert="true">
                          <a className="uk-alert-close" uk-close="true" />
                          <p>{success}.</p>
                        </div>)
                        :
                        <></>}
                    </div>
                  </div>
                  <div className="uk-width-1-1 uk-margin-bottom">
                    <input
                      className="uk-input uk-input-large"
                      type="email"
                      placeholder="E-mail"
                      onInput={(e) => setEmail(e.currentTarget.value)}
                      required
                    />
                  </div>
                  <div className="uk-text-right uk-margin-remove-top">
                    <Link to="/login">
                      Return to login
                    </Link>
                  </div>
                  <div className="uk-width-1-1 uk-width-1-1">
                    <button className="uk-button uk-button-primary uk-button-large">
                      Send Password Reset Email
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
