import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { login, checkLogin } from "../middleware/auth"

export const Login = (props) => {

  // const [username, setUsername] = useState('');
  // const [password, setPassword] = useState('');
  // const [error, setError] = useState();

  useEffect(() => {
    checkLogin();
    window.location('/')
  }, [])

  return <></>

  // const submitForm = async () => {
  //   const req_login = await login(username, password);
  //   if (req_login.success) props.history.push('/');
  //   if (req_login.success) localStorage.setItem('signed-in', "true");
  //   if (!req_login.success) setError(req_login.detail);
  // }

  // return (
  //   <>
  //     <div className="uk-section login-section uk-padding-medium">
  //       <div className="uk-container">
  //         <div className="uk-grid uk-flex-center" uk-grid="true">
  //           <div className="uk-flex-first uk-width-1-2@s uk-width-1-1 uk-text-left uk-first-column">
  //             <div className="uk-card uk-card-default uk-padding-large uk-text-center">
  //               <h1 className="sky">
  //                 <img
  //                   src="../images/fft-icon.jpg"
  //                   className="uk-border-circle crew-logo"
  //                   alt="vfrontier crew login icon"
  //                 ></img>
  //                 Crew Login
  //               </h1>
  //               <form
  //                 className="uk-grid-small uk-grid-match uk-grid"
  //                 uk-grid="true"
  //                 onSubmit={(e) => {
  //                   e.preventDefault();
  //                   submitForm();
  //                 }}>
  //                 <div className="uk-width-1-1">
  //                   <div className="uk-position-relative uk-margin-medium">
  //                     {error ? (
  //                       <div className="uk-alert-danger" uk-alert="true">
  //                         <a className="uk-alert-close" uk-close="true" />
  //                         <p>{error}</p>
  //                       </div>)
  //                       :
  //                       <></>}
  //                   </div>
  //                 </div>
  //                 <div className="uk-width-1-1 uk-margin-bottom-small">
  //                   <input
  //                     className="uk-input uk-input-large"
  //                     type="email"
  //                     placeholder="E-mail"
  //                     onInput={(e) => setUsername(e.currentTarget.value)}
  //                   />
  //                 </div>
  //                 <div className="uk-width-1-1 uk-margin-bottom">
  //                   <input
  //                     className="uk-input uk-input-large"
  //                     type="password"
  //                     placeholder="Password"
  //                     onInput={(e) => setPassword(e.currentTarget.value)}
  //                   />
  //                 </div>
  //                 <div className="uk-text-left uk-margin-remove-top">
  //                   <Link to="/register">
  //                     New to the crew? Create an account
  //                   </Link>
  //                   <Link className="reset-password-link" to="/reset-request">
  //                     Forgot password?
  //                   </Link>
  //                 </div>
  //                 <div className="uk-width-1-1 uk-width-1-1">
  //                   <button className="uk-button uk-button-primary uk-button-large">
  //                     Login
  //                   </button>
  //                 </div>
  //               </form>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
};
